// Heading
$heading-after: $color-tertiary;

.cms-home {
    .pb-homepage-banner {
        .pagebuilder-column {
            @include mq($from: tablet, $until: desktop) {
                width: 50% !important;

                [data-content-type="image"] {
                    img {
                        height: auto !important;
                    }
                }
            }
        }
    }
}

.header.links {

    a {
        &:hover {
            color: $color-white !important;
            text-decoration: underline;
        }
    }
}

.header.content:not(.checkout-index-index .header.content) {

    .action.book-appointment,
    .action.nav-toggle,
    .action.store-locator,
    .action.my-account,
    .action.quote-list,
    .action.showcart {
        color: $color-primary;

        &:before {
            background: $color-tertiary;
        }
    }

    .action.store-locator {
        display: none;
    }
}

.category-grid [data-content-type=pinpoint_pagebuilderenhancedlink]:hover .text-container [data-element=enhanced_link_title_element] {
    color: $color-white;
}

// Newsletter Signup
.lpv2 {
    background-color: transparent !important;
}

.action.requisition-list-button,
.action.new span {
    color: $color-white !important;

    &:visited,
    &:active,
    &:hover {
        color: $color-white !important;
    }

    &:before {
        background-color: $color-white !important;
    }
}

.actions-toolbar.requisition-view-buttons {

    .action {

        &:hover {
            color: $color-white;
        }
    }
}

.edit-company-profile {

    &:hover {
        color: $color-white;
        text-decoration: none;
    }
}

.block-requisition-management {

    .action.requisition-list-button {
        color: $color-primary !important;
    }
}
