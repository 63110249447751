.logo {

    @include mq($from: desktop) {

        img {

            &::after {
                display: none;
            }
        }
    }
}

.store-locator {
    display: none;
}
